<template>
  <div class="component-footer">
    <section class="footer verde white-text" style="background-image: url('/images/back-verde.png');">
      <b-container class="pt-5 pb-5">
        <b-row>
          <b-col col sm="12" class="text-center mb-5 d-none d-sm-none d-md-none d-lg-block">
            <b-img alt="Gobierno de México" src="/images/gobmx.png" class="logo-footer"></b-img>
            <b-img alt="IME" src="/images/separador.png" class="separador "></b-img>
            <b-img alt="Secretaría de Relaciones Exteriores" src="/images/cancilleria.png" class="logo-footer"></b-img>
          </b-col>
          <b-col col sm="12" class="text-center mb-5 d-lg-none">
            <b-img alt="Gobierno de México" src="/images/gobmx.png" class="logo-footer"></b-img><br>
            <b-img alt="Secretaría de Relaciones Exteriores" src="/images/cancilleria.png" class="logo-footer"></b-img>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="12" lg="3" md="6" sm="6" class="mt-4 mb-2" >
            <h5>¿Qué hacemos?</h5>
            <hr class="sm">
            <p class="light">El <strong> Instituto de los Mexicanos en el Exterior</strong> (IME) es un órgano desconcentrado de la
              Secretaría de Relaciones Exteriores bajo el ámbito de la Oficina del Canciller que,
              desde el 16 de abril de 2003, atiende las iniciativas y necesidades comunitarias de los mexicanos
              que viven y trabajan fuera de nu
              estro país.</p>
            
            <b-button
              variant="light" 
              size="sm" 
              style="float:right"
              class="black-text"
              :to="{ name: 'SinglePages', params: { canonical: 'que-hacemos' }}">
              <i class="fas fa-external-link-square-alt"></i>
              Leer más...
            </b-button>

          </b-col>
          <b-col cols="12" lg="3" md="6" sm="6" class="mt-4 mb-2">
            <h5>Acerca del IME</h5>
            <hr class="sm">
            <p><a target="_blank" href="https://www.gob.mx/cms/uploads/attachment/file/134219/2003_04_16_MAT_sre.pdf">Decreto original de creación del IME</a></p>
            <p><a target="_blank" href="https://www.gob.mx/cms/uploads/attachment/file/124955/decreime11.pdf">Reforma publicada el 6 de abril de 2003, al Decreto por el que se crea el IME </a></p>
            <p><a target="_blank" href="#">Reforma publicada al Decreto por el que se crea el IME </a></p>
            <p><router-link :to="{ name: 'SinglePages', params: { canonical: 'aviso-de-privacidad' }}">Aviso de Privacidad</router-link></p>
            <p><a target="_blank" href="https://sre.gob.mx/index.php/avisos-de-privacidad-convocatorias">Avisos de Privacidad de las Convocatorias</a></p>
          </b-col>
          <b-col cols="6" lg="3" md="6" sm="6" class="mt-4 mb-2">
            <h5>Enlaces</h5>
            <hr class="sm">
            <p><a target="_blank" href="https://www.gob.mx/ime">gob.mx</a></p>
            <p><a target="_blank" href="https://datos.gob.mx/">Datos</a></p>
            <p><a target="_blank" href="https://www.gob.mx/publicaciones">Publicaciones </a></p>
            <p><a target="_blank" href="http://portaltransparencia.gob.mx/">Portal de Obligaciones de Transparencia</a></p>
            <p><a target="_blank" href="https://www.infomex.org.mx/gobiernofederal/home.action">PNT</a></p>
            <p><a target="_blank" href="http://www.inai.org.mx/">INAI</a></p>
            <p><a target="_blank" href="https://asociaciones.sre.gob.mx/">Directorio de Asociaciones y Clubes de Oriundos</a></p>
            
          </b-col>
          <b-col cols="6" lg="3" md="6" sm="6" class="mt-4 mb-2">
            <h5>Síguenos</h5>
            <hr class="sm">
            <p>
              <i class="fab fa-facebook " aria-hidden="true"></i>&nbsp;
              <a target="_blank" href="https://www.facebook.com/IMEsremx/">Facebook</a>
            </p>
            <p>
              <i class="fab fa-twitter" aria-hidden="true"></i>&nbsp;
              <a target="_blank" href="https://twitter.com/IME_SRE/">Twitter</a>
            </p>
            <p>
              <i class="fab fa-youtube" aria-hidden="true"></i>&nbsp;
              <a target="_blank" href="https://www.youtube.com/channel/UCGw4lDC7MkgungJ1A4bJrBA/">Youtube</a>
            </p>
            <p>
              <i class="fab fa-instagram" aria-hidden="true"></i>&nbsp;
              <a target="_blank" href="https://www.instagram.com/imesremx/">Instagram</a>
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="text-center light dorado-text mt-3">
            <small>Derechos Reservados 2021-2024 &copy; Instituto de los Mexicanos en el Exterior, Secretaría de Relaciones Exteriores </small>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  components:{},
  props: {
    msg: String
  }
}
</script>
