import axios from "axios";
import authHeader from "@/services/auth-header";

const API_URL_CALENDAR = process.env.VUE_APP_ENDPOINT_CALENDAR;

class wsExternal {
  getAllCalendarHome() {
    return axios.get(
      API_URL_CALENDAR + `/api/v2/calendars/?&sort[1]=updatedAt:desc`,
      {
        headers: authHeader(),
      }
    );
  }

  getAllCalendar(pageSize, page) {
    return axios.get(
      API_URL_CALENDAR +
        `/api/v2/calendars/?&sort[1]=updatedAt:desc&pagination[pageSize]=${pageSize}&pagination[page]=${page}`,
      {
        headers: authHeader(),
      }
    );
  }

  getEvent(uuid) {
    return axios.get(
      API_URL_CALENDAR + `/api/v2/calendars/?filters[uuid][$eq]=${uuid}`,
      {
        headers: authHeader(),
      }
    );
  }
}

export default new wsExternal();
